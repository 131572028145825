(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reboundCards = void 0;
var reboundCards = exports.reboundCards = {
  init: function init() {
    var $reboundsSections = document.querySelectorAll('.section-rebounds');
    if (!$reboundsSections) {
      return;
    }
    Array.prototype.forEach.call($reboundsSections, function ($section, i) {
      reboundCards.setCardsHeight($section);
      window.addEventListener("resize", function () {
        reboundCards.setCardsHeight($section);
      });
    });
  },
  setCardsHeight: function setCardsHeight($section) {
    var $cardsRebounds = $section.querySelectorAll('.card[data-card="rebound"]');
    var arrayCards = [];
    Array.prototype.forEach.call($cardsRebounds, function ($card, i) {
      $card.querySelector('.card__wrap').style.minHeight = '';
      arrayCards[i] = $card.clientHeight;
    });
    var highest = Math.max.apply(Math, arrayCards);
    // console.log(arrayCards, highest);

    Array.prototype.forEach.call($cardsRebounds, function ($card, i) {
      $card.querySelector('.card__wrap').style.minHeight = highest + 'px';
    });
  }
};

},{}],2:[function(require,module,exports){
"use strict";

var _rebounds = require("../../../../../_src/js/components/rebounds");
document.addEventListener('DOMContentLoaded', function (event) {
  _rebounds.reboundCards.init();
});

},{"../../../../../_src/js/components/rebounds":1}]},{},[2]);
